@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
input,
textarea {
  background-color: #fff;
}
.calculator-block .slider-input {
  width: 100% !important;
}
.file-input-custom::-webkit-file-upload-button {
  visibility: hidden;
  width: 0;
  margin-right: -10px;
}
/* global.css */
html,
body,
#root {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.phone-input {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  padding: 10px;

  font-size: 14px;
}
.phone-input:hover {
  border: #000;
}
.phone-input:focus {
  border: #000;
}
.react-tel-input .form-control {
  width: 100% !important;
}
.react-tel-input .form-control:focus {
  border-color: #000 !important;
  box-shadow: none !important;
}
.react-tel-input .special-label {
  color: #71717a;
}
/* 
   #puck-portal-root table{
    width: 100%;
   } */
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
.puck > div > div {
  position: relative !important;
  height: 100% !important;
  min-height: 70vh !important;
  background-color: #f5f5f5 !important;
  .puck-root {
    margin-top: 80px !important;
  }
}
