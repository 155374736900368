.Section:not(.Section .Section) {
  padding-left: 24px;
  padding-right: 24px;
}

.Section:not(.Section .Section) .Section-inner {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
