.Hero {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgb(247, 250, 255) 100%
  );
  display: flex;
  align-items: center;
  position: relative;
}

.Hero-inner {
  display: flex;
  align-items: center;
  position: relative;
  gap: 48px;
  flex-wrap: wrap;
  z-index: 1;
}

@media (min-width: 1024px) {
  .Hero-inner {
    flex-wrap: nowrap;
  }
}

.Hero h1 {
  line-height: 1.1;
  font-size: 48px;
}

@media (min-width: 768px) {
  .Hero h1 {
    font-size: 64px;
  }
}

.Hero-subtitle {
  color: var(--puck-color-grey-4);
  font-size: var(--puck-font-size-m);
  line-height: 1.5;
  margin: 0;
  margin-bottom: 8px;
  font-weight: 300;
}

.Hero--hasImageBackground .Hero-subtitle {
  color: var(--puck-color-grey-2);
}

.Hero-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
}

.Hero-imageOverlay {
  background-image: linear-gradient(
    -90deg,
    rgb(247, 250, 255, 0.7) 0%,
    rgb(247, 250, 255, 0.7) 80%
  );
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .Hero--left .Hero-imageOverlay {
    background-image: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(247, 250, 255) 70%
    );
  }
}

.Hero-bg img {
  height: 100%;
}

.Hero-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

@media (min-width: 768px) {
  .Hero--hasImageBackground .Hero-content {
    max-width: 50%;
  }
}

.Hero--center .Hero-inner {
  justify-content: center;
  text-align: center;
}

.Hero--center .Hero-content {
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.Hero-actions {
  display: flex;
  gap: 16px;
}
